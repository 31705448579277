<template>
    <div class="login-container">
        <b-col sm="12" md="4" lg="4" xl="4">
            <b-overlay :show="loading" rounded="sm">
                <b-card class="login-card">
                    <img src="@/assets/images/cage.png" alt="Фото ринга" class="cage-photo">

                    <b-form @submit="onSubmit">
                        <b-form-group
                            class="text-white"
                            label="Токен ринга:"
                        >
                            <b-form-input
                                v-model="token"
                                type="text"
                                required
                                placeholder="Введите токен ринга"
                            ></b-form-input>
                        </b-form-group>

                        <b-button
                            type="submit"
                            variant="success"
                            size="sm"
                            block
                            loading
                        >
                            Войти
                        </b-button>
                    </b-form>
                </b-card>
            </b-overlay>
        </b-col>
    </div>
</template>

<script>
import axios from '@/axios'

export default {
    data() {
        return {
            token: null,
            loading: false
        }
    },
    methods: {
        onSubmit(e) {
            e.preventDefault()

            this.loading = true;
            
            axios(`correctTime/${this.token}`)
                .then(result => {
                        localStorage.setItem('token', this.token);
                        this.$router.push({ name: 'Home' });

                        this.loading = false;
                    }
                )
                .catch(err => {
                    console.log('ERROR');

                    this.loading = false;
                })
        }
    }
}
</script>

<style lang="scss" scoped>
    .login-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;

        .cage-photo {
            width: 100px;
            margin: 10px auto;
            display: block;
        }

        .login-card {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            border: none;
            background-color: #242526;
        }
    }
</style>